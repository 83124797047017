import React, { useState, useEffect, useContext } from "react";
import LoaderHelper from "../../UtilityComponent/Loading/LoaderHelper";
import AuthService from "../../Api_Module/Api_Services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../UtilityComponent/CustomAlertMessage";
import { ProfileContext } from "../../Context";
import { useOutletContext } from "react-router-dom";
const CurrencyPrefrence = () => {

    const [currencyType, setCurrencyType] = useState();
    const { currency, setCurrency,handleUserDetials } = useContext(ProfileContext)
    const [setActiveTab] = useOutletContext()
    useEffect(() => {
        let URL = window.location.href?.split('/');
        let route = URL.pop();
        setActiveTab(route)
      }, []);
    

    const handleInputChange = (event) => {
        setCurrencyType(event.target.id);
    };

    const handleCurrency = async (currencyType) => {
        LoaderHelper.loaderStatus(true)
        await AuthService.setCurrency(currencyType).then(async result => {
            if (result?.success) {
                try {
                    LoaderHelper.loaderStatus(false);
                    alertSuccessMessage(result?.message);
                    handleUserDetials()
                } catch (error) {
                    LoaderHelper.loaderStatus(false);
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result?.message);
            }
        });
    };

    useEffect(() => {
        // getVerifyDetals();
    }, []);

    // const getVerifyDetals = async () => {
    //     await AuthService.getDetails().then(async result => {
    //         if (result?.success) {
    //             setCurrency(result?.data?.currency_prefrence)
    //         } else {
    //             alertErrorMessage(result?.message);
    //         }
    //     });
    // };

    const resetInput = () => {
        setCurrency("");
    };

    return (
        <>
            <div className="tab-pane  active show" id="CurrencyPill" role="tabpanel" aria-labelledby="Currency-pill">
                <div className="upload-formate mb-6 d-flex justify-content-center align-items-center ">
                    <div className="mb-1 text-center">
                        <h3 className="mb-1 text-center">
                            Currency Preference
                        </h3>
                        <p className="formate mb-0">
                            Select your preferred display currency for all markets
                        </p>
                    </div>
                </div>
                <div className=" row py-4">
                    <div className="col-md-12 col-lg-8 m-auto">
                        <div className="row">

                            <div className="col-md-6">
                                <label className="card-radio-btn mb-3 ">
                                    <input type="radio" name="bitcoin" className={`card-input-element d-none${currency === "USDT" ? "card-input-element d-none active" : ""}`} id="USDT" onChange={handleInputChange} onClick={resetInput} />
                                    <div className="card card-body">
                                        <img alt="" src="/images/coins/tether.png" className="img-fluid check_img" />
                                        <div className="content_head">Tether USD (USDT)</div>
                                    </div>
                                </label>

                            </div>
                            {/* <div className="col-md-6">
                                <label className="card-radio-btn mb-3 ">
                                    <input type="radio" name="bitcoin" className={`card-input-element d-none${currency === "BTC" ? "card-input-element d-none active" : ""}`} id="BTC" onChange={handleInputChange} onClick={resetInput} />
                                    <div className="card card-body">
                                        <img alt="" src="/images/coins/bitcoin.png" className="img-fluid check_img" />
                                        <div className="content_head">Bitcoin (BTC)</div>
                                    </div>
                                </label>

                            </div> */}
                            <div className="col-md-6">
                                <label className="card-radio-btn mb-3">
                                    <input type="radio" name="bitcoin" className={`card-input-element d-none${currency === "INR" ? "card-input-element d-none active" : ""}`} id="INR" onChange={handleInputChange} onClick={resetInput} />
                                    <div className="card card-body">
                                        <img alt="" src="/images/coins/rupee.png" className="img-fluid check_img" />
                                        <div className="content_head"> Rupee (INR)</div>
                                    </div>
                                </label>

                            </div>
                            {/* <div className="col-md-6">

                                <label className="card-radio-btn ">
                                    <input type="radio" name="bitcoin" className={`card-input-element d-none${currency === "BNB" ? "card-input-element d-none active" : ""}`} id="BNB" onChange={handleInputChange} onClick={resetInput} />
                                    <div className="card card-body">
                                        <img alt="" src="/images/coins/bnb.png" className="img-fluid check_img" />
                                        <div className="content_head">BNB</div>
                                    </div>
                                </label>
                            </div> */}

                        </div>
                        <button className=" mt-5 btn btn-gradient btn-medium justify-content-center w-100" type="button" onClick={() => handleCurrency(currencyType)}><span> Save Currency Preference </span></button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CurrencyPrefrence;