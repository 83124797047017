import React, { useEffect, useState } from "react";
import AuthService from "../../Api_Module/Api_Services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../UtilityComponent/CustomAlertMessage";
import DefaultInput from "../../UtilityComponent/DefaultInput";
import { validIfscCode, validAccountno, } from "../../UtilityComponent/Validation";
import LoaderHelper from "../../UtilityComponent/Loading/LoaderHelper";
import { useOutletContext } from "react-router-dom";

const BankAccount = () => {
    const [holderName, setHolderName] = useState('');
    const [bankName, setBankname] = useState('');
    const [branchAddress, setBranchAddress] = useState();
    const [accountNumber, setAccountNumber] = useState('');
    const [ifscCode, setIfscCode] = useState('');
    const [bankId, setBankId] = useState("");
    const [bankdetails, setBankDetails] = useState({});
    const [setActiveTab] = useOutletContext()
    useEffect(() => {
        let URL = window.location.href?.split('/');
        let route = URL.pop();
        setActiveTab(route)
    }, []);


    const handleInputChange = (event) => {
        switch (event.target.name) {
            case "bankName":
                setBankname(event.target.value);
                break;
            case "branchAddress":
                setBranchAddress(event.target.value);
                break;
            case "accountNumber":
                setAccountNumber(event.target.value);
                break;
            case "ifscCode":
                setIfscCode(event.target.value);
                break;
            case "holderName":
                setHolderName(event.target.value);
                break;
            default:
                break;
        }
    };

    const resetInput = () => {
        setHolderName("");
        setIfscCode("");
        setAccountNumber("");
        setBranchAddress("");
    };

    const handleMessageQuery = async (bankName, holderName, accountNumber, ifscCode, branchAddress) => {
        try {
            LoaderHelper.loaderStatus(true)
            const result = await AuthService.addUserBank(bankName, holderName, accountNumber, ifscCode, branchAddress)
            LoaderHelper.loaderStatus(false)
            if (result?.success) {
                alertSuccessMessage(result?.message);
                getBankDetals();
                resetInput();
            }
            else { alertErrorMessage(result?.message) }
        } catch (error) { alertErrorMessage(error) }
        finally { LoaderHelper.loaderStatus(true) }
    };

    const handleEditBank = async (bankName, holderName, accountNumber, ifscCode, branchAddress,id) => {
        try {
            LoaderHelper.loaderStatus(true)
            const result = await AuthService.editUserBank(bankName, holderName, accountNumber, ifscCode, branchAddress,id)
            if (result?.success) {
                alertSuccessMessage(result?.message);
                getBankDetals();
                resetInput();
            }
            else { alertErrorMessage(result?.message) }
        } catch (error) { alertErrorMessage(error) }
        finally { LoaderHelper.loaderStatus(false) }
    };


    const getBankDetals = async () => {
        try {
            LoaderHelper.loaderStatus(true)
            const result = await AuthService.getUserBank()
            if (result?.success) setBankDetails(result?.data[0] || {});
        } finally { LoaderHelper.loaderStatus(false) }
    };


    const handleIfsc = async (ifscCode) => {
        // await AuthService.getIfscDetails(ifscCode).then(async result => {
        //     if (result?.data === "Invalid IFSC Code") {
        //         try {
        //             // alertSuccessMessage(result?.data);
        //             setBranchAddress("");

        //         } catch (error) {
        //             // alertErrorMessage(error);
        //             // console.log('error', `${error}`);
        //         }
        //     } else {
        //         // /* alertErrorMessage(result?.data); */
        //         setBranchAddress(result?.data?.BRANCH);

        //     }
        // });
    };

    const handleEditModal = (data) => {
        setHolderName(data?.holder_name);
        setIfscCode(data?.ifsc);
        setAccountNumber(data?.account_number);
        setBranchAddress(data?.branch);
        setBankname(data?.bank_name);
        setBankId(data?.id);
    };


    useEffect(() => {
        getBankDetals();
    }, []);

    return (
        <>
            <div class="upload-formate mb-6 d-flex justify-content-between align-items-center">
                <div>
                    <h3 class="mb-1 ">
                        Payment Options
                    </h3>
                    <p class="formate mb-0">
                        Select your payment options for all transactions.
                    </p>
                </div>
                {Object.keys(bankdetails).length === 0 ? <button class=" btn btn-gradient btn-small justify-content-center" data-bs-toggle="modal" data-bs-target="#AddBank" type="button">
                    <span>Add New </span></button>
                    : <button class=" btn btn-gradient btn-small justify-content-center" data-bs-toggle="modal" data-bs-target="#AddBank" type="button" onClick={() => handleEditModal(bankdetails)}>
                        <span>Edit </span></button>}

            </div>
            {Object.keys(bankdetails).length === 0 ?
                <div className="favouriteData">
                    <img src="/images/no-data.svg" className="img-fluid" width="96" height="96" alt="" />
                    <br />
                    <p className="mt-3 mb-4" > No Data Found. </p>
                </div>
                :
                <div class="form-field-wrapper bank-acc mb-3 ">
                    <div class="acc_details">
                        <div class="row">
                            <div class="col-md-6">
                                <h5 class="text-start"><small>Bank Account</small> <br /> {bankdetails?.bank_name}</h5>
                            </div>
                            <div class="col-md-6">
                                <h5 class="text-start"><small>Account Number</small> <br /> {bankdetails?.account_number}</h5>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <h5 class="text-start"><small>IFSC Code</small> <br />{bankdetails?.ifsc}</h5>
                            </div>
                            <div class="col-md-6">
                                <h5 class="text-start"><small>Branch Address</small> <br />{bankdetails?.branch}</h5>
                            </div>
                        </div>
                    </div>
                </div>}

            <div class="modal fade" id="AddBank" tabindex="-1" aria-labelledby="AddBankLaebl" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header flex-column px-8">
                            <h3 class="modal-title" id="placeBitLaebl">Add Account details</h3>
                            <button type="button" class="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close"><i class="ri-close-fill"></i></button>
                        </div>
                        <div class="modal-body px-8 py-5">
                            <form >
                                {/* <div class="form-group mb-4 d-inline-block w-100 ">
                                    <label for="royality" class="form-label">Select Bank Type</label>
                                    <select id="royality" name="accountType" className="input-select" value={accountType} onChange={handleInputChange}>
                                        <option value="saving">Saving Account</option>
                                        <option value="Current">Current Account</option>
                                        <option value="deposit">Fixed Deposit account</option>
                                    </select>
                                </div> */}

                                <div class="form-group mb-4 ">
                                    <label for="bit"> Bank Name </label>
                                    <input type="text" id="bit" name="bankName" value={bankName} onChange={handleInputChange} />
                                </div>

                                <div class="form-group mb-4">
                                    <label for="bit"> Account Holder Name </label>
                                    <input type="text" name="holderName" value={holderName} onChange={handleInputChange} />
                                </div>
                                <div class="form-group mb-4">
                                    <label for="bit"> Account Number</label>
                                    <DefaultInput errorStatus={validAccountno(accountNumber)} errorMessage={validAccountno(accountNumber)} id="bit" name="accountNumber" type="number" value={accountNumber} onChange={handleInputChange} />
                                </div>

                                <div class="form-group mb-4">
                                    <label for="bit"> SWIFT Code/IFSC Code </label>
                                    <DefaultInput errorStatus={validIfscCode(ifscCode)} errorMessage={validIfscCode(ifscCode)} id="bit" name="ifscCode" type="text" value={ifscCode} onChange={handleInputChange} />
                                </div>

                                <div class="form-group mb-4">
                                    <label>Branch Address</label>
                                    <input type="text" name="branchAddress" value={branchAddress} onChange={handleInputChange} onFocus={() => handleIfsc(ifscCode)} />
                                </div>
                            </form>
                        </div>
                        <div class="modal-footer px-8">
                        {Object.keys(bankdetails).length === 0 ?
                            <button type="button" class="btn btn-gradient btn-small w-100 justify-content-center" data-bs-dismiss="modal" aria-label="Close" disabled={!accountNumber || !ifscCode || !(validAccountno(accountNumber) === undefined) || !(validIfscCode(ifscCode) === undefined)} onClick={() => handleMessageQuery(bankName, holderName, accountNumber, ifscCode, branchAddress)}><span>Add Bank</span></button>:
                            <button type="button" class="btn btn-gradient btn-small w-100 justify-content-center" data-bs-dismiss="modal" aria-label="Close" disabled={!accountNumber || !ifscCode || !(validAccountno(accountNumber) === undefined) || !(validIfscCode(ifscCode) === undefined)} onClick={() => handleEditBank(bankName, holderName, accountNumber, ifscCode, branchAddress,bankId)}><span>Edit Bank</span></button>}
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default BankAccount;