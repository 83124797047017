import React from 'react'
import DataTable from 'react-data-table-component';


const DataTableBase = (props) => {
    return (
        <DataTable
            direction="auto"
            responsive
            subHeaderAlign="right"
            subHeaderWrap
            striped
            highlightOnHover
            fixedHeader
            theme='dark'
            {...props}
        />
    )
}

export default DataTableBase