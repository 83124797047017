const appUrl = "https://backend.fipnext.com";
export const deployedUrl = `${window.origin}/`

export const ApiConfig = {
  // =========EndPoints========== //
  getcode: "verify-otp",
  getOtp: "send-otp",
  login: "login",
  register: "register",
  getDetails: "profile",
  changePassword: "change_password",
  updateSettings: "edit-profile",
  googleAuth: "generate-google-qr",
  update2fa: "enable-2fa",
  getHistoricalData: 'historical-data',
  placeOrder: 'place-order',
  pastOrder: 'past-order',
  cancelOrder: 'cancel-order',
  categoryList: 'coin-category-list',
  coinDetails: 'coin-details',
  favoriteCoin: 'favorite-coin',
  favoriteList: 'favorite-list',
  tradeHistory: 'trade-history',
  addFavourite: "exch/addfavcoin",
  getFavouriteList: "exch/getallfavcoin",
  setCurrency: "currency-preference",
  userfunds: "user-wallet",
  generateAddress: "generate-address",
  estimatedPortfolio: "estimated-portfolio",
  forgotpassword: "forgot_password",
  totalrefercount: "total_refer_count",
  referalcode: "user_refer_code",
  withdrawalCurrency: "withdrawal-currency",
  verifyDeposit: 'verify-deposit',
  walletDepositHistory: "wallet-deposit-history",
  walletWithdrawalHistory: "wallet-withdrawal-history",
  addkyc: "submit-kyc",
  activityLogs: "activity-logs",
  skipModel: "skip-model",
  userTradeHistory: "user-trade-history",
  notificationList: "notification-list",
  getPairs: "get-pairs",
  userReferralList: "user_referral_list",
  quick_buy_sell: "quick_buy_sell",
  quickBuySellHistory: "history",
  availabe_staking: "availabe_staking",
  pending_staking_history: "staking_income",
  staking_history: "staking_history",
  place_staking: "place_staking",
  break_staking: "break_staking",
  getUserBankDetails: "get_user_bank_details",
  comissionHistory:"user-admin-commission-history",
  userAdminTrading:"user_admin_trading",
  addUserBank:"add_user_bank_details",
  getUserBank:"get_user_bank_details",
  editUserBank:"edit_user_bank_details",

  // ============URLs================ //
  baseUrl: `${appUrl}/`,
  appUrl: `${appUrl}/`,
  baseAuth: `${appUrl}/v1/user/`,
  baseAdmin: `${appUrl}/v1/admin/`,
  baseWallet: `${appUrl}/v1/wallet/`,
  baseExchange: `${appUrl}/v1/exchange/`,
  baseTrans: `${appUrl}/v1/transaction/`,
  baseKyc: `${appUrl}/kyc/`,
  baseCoin: `${appUrl}/v1/coin/`,
  baseSwap: `${appUrl}/v1/qbs/`,
  baseStacking: `${appUrl}/v1/staking/`,


  // ============webSocketUrl================ //
  webSocketUrl: appUrl,

};
