// Makes requests to CryptoCompare API

import AuthService from "../../Api_Module/Api_Services/AuthService";


export async function makeApiRequest(fromSym, toSym, to,url) {
	if (fromSym == "undefined") {
		return
	}
	try {
		const response = await AuthService?.cryptoCompareApi(fromSym, toSym, to,url);
		return response;
	} catch (error) {
		throw new Error(error.status);
	}
}
export async function makeApiRequest2(fromSym, toSym, from, to) {
	if (fromSym == "undefined") {
		return
	}
	try {
		const response = await AuthService?.getHistoricalData(fromSym, toSym, from, to);
		return response;
	} catch (error) {
		throw new Error(error.status);
	}
}

// Generates a symbol ID from a pair of the coins
export function generateSymbol(exchange, fromSymbol, toSymbol) {
	const short = `${fromSymbol}/${toSymbol}`;
	return {
		short,
		full: `${exchange}:${short}`,
	};
}

// Returns all parts of the symbol
export function parseFullSymbol(fullSymbol) {
	const match = fullSymbol?.split('/');
	if (!match) {
		return null;
	}
	return {
		fromSymbol: match[0],
		toSymbol: match[1],
	};
}


