import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const authToken = sessionStorage.getItem("FIP-Invest_AUTH_TOKEN");

  return (
    <footer className="footer-wrapper">
      <div className="footer-inner">
        <div className="container">
          <div className="row">
            <div class="col-lg-5 col-12 pb-2">
              <a class="navbar-brand d-flex  mb-2" href="/">
                <img src="/images/fiplogo2.svg" class="logo logo-light" />
                <img src="/images/fiplogo_light2.svg" class="logo logo-dark" />
              </a>
              <p class="mb-2 footer_text text-justify">fipnext is a blockchain-based banking platform for crypto traders and investors,
                and aims to connect the world of traditional finance and cryptocurrencies.</p>
              <div class="d-flex  social  ">
                <a rel="noreferrer" className="joc_social" target="_blank" href="https://www.facebook.com/fipnext" ><i class="ri-facebook-line"></i></a>
                <a rel="noreferrer" className="joc_social" target="_blank" href="https://x.com/fipnext"><i class="ri-twitter-line "></i></a>
                <a rel="noreferrer" className="joc_social" target="_blank" href="https://www.instagram.com/fipnext"><i class="ri-instagram-line "></i></a>
                <a className="joc_social" target="_blank" href="https://linkedin.com/fipnext" rel="noreferrer"><i class="ri-linkedin-line"></i></a>
                <a className="joc_social" target="_blank" href="https://youtube.com/channel/fipnext" rel="noreferrer"><i class="ri-youtube-line ri-xl"></i></a>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-12 mb-4 mb-lg-0 ps-md-4">
              <div className="footer-widget">
                <h4>About Us</h4>
                <ul className="footer-list-widget">
                  <li>
                    <Link to="/AboutUs">About </Link>
                  </li>
                  <li>
                    <Link to="/TermsOfUsePage">Terms of Use</Link>
                  </li>
                  <li>
                    <Link to="/PrivacyPolicyPage">Privacy & KYC Policy</Link>
                  </li>
                  <li>
                    <Link to="/RiskDisclosure">Risk Disclosure</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-2 col-md-6 col-12 mb-4 mb-lg-0  ps-md-4">
              <div className="footer-widget">
                <h4>Services</h4>
                <ul className="footer-list-widget">
                  <li>
                    <Link to="/BuyCrypto">Buy Crypto</Link>
                  </li>
                  <li>
                    <Link to="/Fees">Fees</Link>
                  </li>
                  <li>
                    <Link to={authToken ? "/ReferralPage" : "/Earn"}>Referral Program</Link>
                  </li>
                  <li>
                    <Link to="/listingcoin">Listing Application</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-12 mb-4 mb-lg-0  ps-md-4">
              <div className="footer-widget">
                <h4>Contact Us</h4>
                <ul className="footer-list-widget">
                  <li>
                    <Link to="/FAQ">Help Center/ FAQ</Link>
                  </li>
                  <li>
                    <Link to="/Career">Career</Link>
                  </li>
                  <li>
                    <Link to="/Support">Submit a Request</Link>
                  </li>
                  <li>  <a rel="noreferrer"  target="_blank" href="mailto:admin@fipnext.com " >admin@fipnext.com </a>  </li>
                </ul>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className="copyright text-center">
        <div className="container">
          <p>
            Copyright ©️ fipnext 2024. All Right Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
