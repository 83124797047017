import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper";
import LoaderHelper from "../../UtilityComponent/Loading/LoaderHelper";
import AuthService from "../../Api_Module/Api_Services/AuthService";
import { alertSuccessMessage, alertWarningMessage } from "../../UtilityComponent/CustomAlertMessage";
import { CountryDetails } from "../../UtilityComponent/CountryList";

const ForgotPassPage = () => {
  const [signId, setSignId] = useState("");
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [cPassword, setCpassword] = useState("");
  const navigate = useNavigate();
  const [isShow, setIsShow] = useState(false);
  const [passwordError, setPasswordError] = useState(null);
  const [passwordError2, setPasswordError2] = useState(null);
  const [passwordType, setpasswordType] = useState(true);
  const [passwordType2, setpasswordType2] = useState(true);
  const [countryCode, setCountryCode] = useState("91:India");

  const handleForgot = async (signId, otp, password, cPassword) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.forgotPassword(signId, otp, password, cPassword).then(async (result) => {
      if (result?.success) {
        alertSuccessMessage(result.authRes);
        navigate("/login");
      }
    }
    );
  };

  const handleGetCode = async (signId, countryCode, type) => {
    if (type === 'phone') {
      const [code, label] = countryCode.split(':');
      let filteredCountry = CountryDetails?.filter((data) => data?.phone === code && data?.label === label)?.map((item) => item?.phoneLength);
      if (signId?.length !== filteredCountry[0]) {
        alertWarningMessage(`Please enter valid phone number for ${label}`)
        return
      }
    };
    LoaderHelper.loaderStatus(true);
    await AuthService.getOtp(signId, 'forgot').then(async (result) => {
      if (result?.success) {
        alertSuccessMessage(result?.message);
        setIsShow(true);
      }
    });
  };
  const validatePassword = (e) => {
    setPassword(e.target.value);
    const validationCharac = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/);
    if (e.target.value.match(validationCharac)) {
      setPasswordError(false)
    } else {
      setPasswordError(true)
    }
  };


  return (
    <>
      <section className="inner-page-banner pb-0 login_page  ">
       
        <div className="container">
          <div className="row align-items-center  justify-content-center">
            {/* <div className="col-xl-7 col-lg-12 ">
              <div className="poster_slider">
                <h2 className="mb-5  ">
                  Welcome to <br />{" "}
                  <span className="text-gradient">fipnext</span>{" "}
                </h2>
                <Swiper
                  className="market_slider  pb-11"
                  spaceBetween={10}
                  loop={true}
                  autoplay={{
                    delay: 2000,
                  }}
                  pagination={{
                    dynamicBullets: true,
                  }}
                  modules={[Autoplay, Pagination, Navigation]}
                  breakpoints={{
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    1024: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                  }}
                >
                  <SwiperSlide>
                    <a href="#/" className="main_poster">
                      {" "}
                      <img alt="" src="/images/poster_1.png" />
                    </a>
                  </SwiperSlide>
                  <SwiperSlide>
                    <a href="#/" className="main_poster">
                      {" "}
                      <img alt="" src="/images/poster_2.png" />
                    </a>
                  </SwiperSlide>
                  <SwiperSlide>
                    <a href="#/" className="main_poster">
                      {" "}
                      <img alt="" src="/images/poster_3.png" />
                    </a>
                  </SwiperSlide>
                  <SwiperSlide>
                    <a href="#/" className="main_poster">
                      {" "}
                      <img alt="" src="/images/poster_1.png" />
                    </a>
                  </SwiperSlide>
                  <SwiperSlide>
                    <a href="#/" className="main_poster">
                      {" "}
                      <img alt="" src="/images/poster_2.png" />
                    </a>
                  </SwiperSlide>
                  <SwiperSlide>
                    <a href="#/" className="main_poster">
                      {" "}
                      <img alt="" src="/images/poster_3.png" />
                    </a>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div> */}
            <div className="col-lg-6 col-12 mx-auto">
              <div class="page_title">
                <h2 class="d-block text-center mb-5"> Forgot Password </h2>
              </div> 
              {/* <div className="signup-wrapper"> */}
                <div className="custom-form ticket-form mb-5 mb-lg-0">
                      
                  <div className={`sign-in_tab ${isShow && "d-none"} `} id="qwert">
                    {/* <div className="mb-3"> */}
                      {/* <h3 className="mb-2  ">Forgot Password</h3> */}
                      {/* <p className="">No worries, we'll send you reset instructions</p> */}
                      {/* <ul className="nav custom-tabs my-1">
                        <li>
                          <a
                            className="active"
                            data-bs-toggle="tab"
                            href="#login_Mobile"
                            onClick={() => { setSignId('') }}
                          >
                            Mobile
                          </a>
                        </li>
                        <li>
                          <a data-bs-toggle="tab" href="#login_email" className="" onClick={() => { setSignId('') }}
                          >
                            Email
                          </a>
                        </li>
                      </ul> */}

                      
                    {/* </div> */}

                    <div className="card-header login-header" >
                        <ul className="nav nav-pills mb-3 login-pills">
                        <li>
                          <a
                            className="active  nav-link"
                            data-bs-toggle="tab"
                            href="#login_Mobile"
                            onClick={() => { setSignId('') }}
                          >
                            Mobile
                          </a>
                        </li>
                        <li>
                          <a data-bs-toggle="tab" href="#login_email" className=" nav-link" onClick={() => { setSignId('') }}
                          >
                            Email
                          </a>
                        </li>
                          </ul>  
                      </div>  

                      
                    <div className="tab-content pt-4">
                      <div
                        className="tab-pane show px-0 container active"
                        id="login_Mobile"
                      >
                        <form action="#">
                          <div className="row">
                            <div className="col-12">
                              <div className="fleld-box">
                                <label htmlFor="email" className="form-label">
                                  Select Country
                                </label>
                              </div>
                            </div>
                            <div className="col-12 ">
                              <div className="field-box "> 

                                <select name="countryCode" className="form-control form-select" id="" value={countryCode} onChange={(e) => setCountryCode(e.target.value)}>
                                  <optgroup >
                                    {CountryDetails?.map(value => {
                                      return <option value={`${value?.phone}:${value?.label}`} key={`${value?.phone}:${value?.label}`}>
                                        {`${value?.label}  (+${value?.phone})`}
                                      </option>
                                    })}
                                  </optgroup>
                                </select>
                              </div>
                            </div>
                            <div className="col-12 ">
                              <div className="field-box">
                              <label htmlFor="Code" className="form-label">
                                  Mobile
                                </label>
                                <input
                                  id="mobile"
                                  type="number"
                                  className="form-control"
                                  placeholder="Enter mobile number"
                                  value={signId}
                                  onWheel={(e) => { e.target.blur() }}
                                  onChange={(e) => setSignId(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-md-12 mb-3 mt-3">
                              <div className="field-box">
                                <button
                                  className="btn btn-gradient w-100 justify-content-center btn-medium {
                                  "
                                  type="button"
                                  onClick={() => handleGetCode(signId, countryCode, 'phone')}
                                >
                                  <span>Next</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="tab-pane px-0 container" id="login_email">
                        <form action="#">
                          <div className="row">
                            <div className="col-md-12 mb-3">
                              <div className="field-box">
                                <label htmlFor="email" className="form-label">
                                  Email
                                </label>
                                <input
                                  id="email"
                                   className="form-control"
                                  type="text"
                                  placeholder="Please enter Email"
                                  value={signId}
                                  onChange={(e) => setSignId(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-md-12 mb-3 mt-3">
                              <div className="field-box">
                                <button
                                  className="btn btn-gradient w-100 justify-content-center btn-medium"
                                  type="button"
                                  onClick={() => handleGetCode(signId)}
                                >
                                  <span>Next</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="row justify-content-center text-center">
                      <div className="col-lg-12">
                        Back to{" "}
                        <Link to="/login" className="color-primary">
                          Sign in
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className={`new_password_tab ${!isShow && "d-none"}`}>
                    <div className="mb-4">
                      {/* <h3 className="mb-4">Reset password</h3> */}
                      <p className="medium">Your Code will be sent to - {signId}</p>
                    </div>
                    <form action="#">
                      <div className="row">
                        <div className="col-md-12 ">
                          <div className="field-box">
                            <label htmlFor="Code" className="form-label">
                              Enter Code
                            </label>
                            <input
                              id="Verification"
                              type="number"
                              className="form-control"
                              placeholder=""
                              value={otp}
                              onChange={(e) => setOtp(e.target.value)}
                              onWheel={(e) => { e.target.blur() }}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="field-box">
                            <label htmlFor="Code" className="form-label">
                              New Password
                            </label>
                            <div className="field-otp-box">
                              <input
                              className="form-control"
                                id="new_Password"
                                type={passwordType ? "password" : 'text'}
                                placeholder=""
                                value={password}
                                onChange={(e) => validatePassword(e)}
                              />
                              <a href="#/" className="show_password opt_btn btn-sm" onClick={() => { setpasswordType(!passwordType) }}>
                                {passwordType ? <i className="ri-eye-off-line" />
                                  : <i className="ri-eye-line" />}
                              </a>
                            </div>
                            {passwordError && <span style={{ color: "red", fontWeight: "300" }}>Password must be at least 8 characters including characters, number and special character.</span>}

                          </div>
                        </div>
                        <div className="col-md-12 ">
                          <div className="field-box">
                            <label htmlFor="Code" className="form-label">
                              Confirm Password
                            </label>
                            <div className="field-otp-box">
                              <input
                              className="form-control mb-0"
                                id="confirm_new_Password"
                                type={passwordType2 ? "password" : 'text'}
                                placeholder=""
                                value={cPassword}
                                onChange={(e) => { setCpassword(e.target.value); { setPasswordError2(e.target.value === password ? false : true) } }}
                              />
                              <a href="#/" className="show_password opt_btn btn-sm" onClick={() => { setpasswordType2(!passwordType2) }}>
                                {passwordType2 ? <i className="ri-eye-off-line" />
                                  : <i className="ri-eye-line" />}
                              </a>
                            </div>
                            {passwordError2 && <span className="text-danger pt-1 d-block" style={{ fontWeight: "300" }}> Password does not match </span>}
                          </div>
                        </div>
                        <div className="col-md-12 mb-4 mt-5  ">
                          <div className="field-box">
                            <button disabled={passwordError || passwordError === null || passwordError2 || passwordError2 === null}
                              className="btn btn-gradient w-100 justify-content-center btn-medium "
                              type="button"
                              onClick={() => handleForgot(signId, otp, password, cPassword)}
                            >
                              <span> Confirm </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div className="row justify-content-center text-center">
                      <div className="col-lg-12">
                        Back to{"  "}
                        <Link to="/login" className="color-primary">
                          Sign in
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              {/* </div> */}
            </div>
          </div>
          {/* </div> */}
        </div>
      </section>
    </>
  );
};

export default ForgotPassPage;
